import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/Account/LoginView.vue'
import RegisterView from '../views/Account/RegisterView.vue'
import CompanyView from '../views/Company/CompanyView.vue'
import CompanyReportView from '../views/Company/report/CompanyReportView.vue'
import TempCompanyReportView from '../views/Company/temp/CompanyView.vue'
import Temp2CompanyReportView from '../views/Company/temp2/CompanyView.vue'
import CityView from '../views/City/CityView.vue'
import DocumentsView from '../views/Ai/DocumentsView.vue'
import CozeDocumentsView from '../views/Ai/CozeDocumentsView.vue'
import ForgetPasswordView from '../views/Account/ForgetPasswordView.vue'
import AdminView from '../views/Admin/AdminView.vue'
import NewsEdition from '../views/Admin/NewsEdition.vue'
import AccountInfoView from '../views/Account/AccountInfoView.vue'
import EditNameAndProvinceView from '../views/EditNameAndProvince.vue'
import EditProductAndTargetView from '../views/EditProductAndTarget.vue'
import EditModulesView from '../views/EditModules.vue'
import ProjectResultView from '../views/ProjectResultView.vue'
import ProjectsView from '../views/ProjectsView.vue'
import SubjectView from '../views/Subject/SubjectView.vue'
import DataView from '../views/DataView.vue'
import ImportDataView from '../views/ImportDataView.vue'
import ProjectTemplatesView from '../views/ProjectTemplatesView.vue'
import ProjectVision from '../views/ProjectVision.vue'
import ProjectVision2 from '../views/ProjectVision2.vue'
import NotFoundView from '../views/404.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    children: [
      {
        path: 'news/edit',
        name: 'news-edit',
        component: NewsEdition,
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: ForgetPasswordView
  },
  {
    path: '/account/info',
    name: 'account-info',
    component: AccountInfoView
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView,
  },
  {
    path: '/subject',
    name: 'subject',
    component: SubjectView,
  },
  {
    path: '/project/edit/np/:projectUuid',
    name: 'edit-project-np',
    component: EditNameAndProvinceView,
  },
  {
    path: '/project/edit/tp/:projectUuid',
    name: 'edit-project-tp',
    component: EditProductAndTargetView,
  },
  {
    path: '/project/edit/modules/:projectUuid',
    name: 'edit-project-modules',
    component: EditModulesView,
  },
  {
    path: '/project/result/:projectUuid',
    name: 'project-result',
    component: ProjectResultView,
  },
  {
    path: '/project/vision/:projectUuid',
    name: 'project-vision',
    component: ProjectVision,
  },
  {
    path: '/project/vision2/:projectUuid',
    name: 'project-vision2',
    component: ProjectVision2,
  },
  {
    path: '/templates',
    name: 'project-templates',
    component: ProjectTemplatesView,
  },
  {
    path: '/data',
    name: 'data-center',
    component: DataView,
  },
  {
    path: '/data/import',
    name: 'data-import',
    component: ImportDataView,
  },
  {
    path: '/city',
    name: 'city',
    component: CityView,
  },
  {
    path: '/company',
    name: 'company',
    component: CompanyView,
  },
  {
    path: '/company/report/dsmkec',
    name: 'company-report',
    component: CompanyReportView,
  },
  {
    path: '/company/ndedsmkec',
    name: 'company-report-tmp',
    component: TempCompanyReportView,
  },
  {
    path: '/company/dsmkec',
    name: 'company-report-tmp2',
    component: Temp2CompanyReportView,
  },
  // {
  //   path: '/ai/documents',
  //   name: 'ai-documents',
  //   component: DocumentsView,
  // },
  // {
  //   path: '/ai/documents',
  //   name: 'ai-coze-documents',
  //   component: CozeDocumentsView,
  // },
  {  
    path: '/:catchAll(.*)', // 这是一个通配符路由，会匹配所有找不到的路由  
    name: 'not-found',  
    component: NotFoundView,  
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

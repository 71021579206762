<template>
  <el-row style="height: 100vh; width: 100vw;">
    <el-menu background-color="#3c3c68" text-color="#ffffff1" :collapse="isCollapse" :default-active="activeIndex" class="el-menu-vertical-demo custom-menu"  @select="handleSelect">
      <el-menu-item index="1"  class="custom-menu-title">
        <template #title>
          <h2>氢界大数据平台</h2>
        </template>
      </el-menu-item>
      <el-sub-menu index="2">
        <template #title>
          <el-icon><ChatLineRound /></el-icon>
          <span>资讯库</span>
        </template>
        <el-menu-item index="2-1">筛选</el-menu-item>
        <el-menu-item index="2-2">编辑与发布</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="3">
        <template #title>
          <el-icon><OfficeBuilding /></el-icon>
          <span>企业库</span>
        </template>
        <el-menu-item index="3-1">筛选</el-menu-item>
        <el-menu-item index="3-2">编辑与发布</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="4">
        <template #title>
          <el-icon><Tickets /></el-icon>
          <span>项目库</span>
        </template>
        <el-menu-item index="4-1">筛选</el-menu-item>
        <el-menu-item index="4-2">AI 提取</el-menu-item>
        <el-menu-item index="4-3">氢能制取</el-menu-item>
        <el-menu-item index="4-4">氢能储存</el-menu-item>
        <el-menu-item index="4-5">氢能运输</el-menu-item>
        <el-menu-item index="4-6">氢能加注</el-menu-item>
        <el-menu-item index="4-7">氢能应用</el-menu-item>
      </el-sub-menu>
      <el-menu-item index="5" disabled>
        <el-icon><Grid /></el-icon>
        <template #title>公共信息库</template>
      </el-menu-item>
    </el-menu>
    <el-container style="width: 85%;">
      <el-header style="height: 8vh; width: 100%; color: black;">
        <el-col :span="4">
          <el-switch
            v-model="isCollapse"
            inline-prompt
            active-text="折叠"
            inactive-text="展开"
          />
        </el-col>
        <el-divider></el-divider>
      </el-header>
      <el-main style="height: 92vh;">
        <AdminHome v-if="currentComponent == 'AdminHome'" />
        <NewsSelection v-if="currentComponent == 'NewsSelection'" />
        <ProjectsSelection v-if="currentComponent == 'ProjectsSelection'" />
        <ProjectAI v-if="currentComponent == 'ProjectAI'" />
        <CompanySelection v-if="currentComponent == 'CompanySelection'" />
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-row>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import {
  OfficeBuilding,
  HomeFilled,
  Grid,
  Tickets,
  ChatLineRound
} from '@element-plus/icons-vue';

import AdminHome from './AdminHome.vue';
import NewsSelection from './NewsSelection.vue';
import ProjectsSelection from './ProjectsSelection.vue';
import ProjectAI from './ProjectAI.vue';
import CompanySelection from './CompanySelection.vue';

onMounted(() => {
  if (window.location.pathname !== '/admin') {
    currentComponent.value = ''
  }
});

const isCollapse = ref(false)
// 假设这是你的菜单项数据结构
const menuItems = [
  // { index: '1', component: 'AdminHome' },
  {
    index: '2',
    component: 'NewsView',
    children: [
      { index: '2-1', component: 'NewsSelection' },
      { index: '2-2', component: 'NewsEditView' },
    ],
  },
  {
    index: '3',
    component: 'CompanyView',
    children: [
      { index: '3-1', component: 'CompanySelection' },
      { index: '3-2', component: 'CompanyEditView' },
    ],
  },
  {
    index: '4',
    component: 'ProjectView',
    children: [
      { index: '4-1', component: 'ProjectsSelection' },
      { index: '4-2', component: 'ProjectAI' },
      { index: '4-3', component: 'HydrogenProductionView' },
      { index: '4-4', component: 'HydrogenStorageView' },
      { index: '4-5', component: 'HydrogenTransportationView' },
      { index: '4-6', component: 'HydrogenRefuelingView' },
      { index: '4-7', component: 'HydrogenApplicationView' },
    ],
  },
  { index: '5', component: 'InfoView', disabled: true },
];

const activeIndex = ref('2-1');
const currentComponent = ref('NewsSelection');
const handleSelect = (index: string) => {
  let selectedMenuItem = menuItems.find(
    (item) => item.index === index || item.children?.some((child) => child.index === index),
  );

  // 确保我们找到的是子菜单项，如果不是，继续查找直到找到子菜单项
  while (selectedMenuItem && selectedMenuItem.children) {
    selectedMenuItem = selectedMenuItem.children.find(child => child.index === index);
  }

  if (selectedMenuItem) {
    currentComponent.value = selectedMenuItem.component;
    activeIndex.value = index;
  }
  console.log(currentComponent.value)
};
</script>

<style>
.el-menu-vertical-demo {
  width: 200px;
  height: 100vh
}


/* 自定义样式 */
.custom-menu .el-menu-item,
.custom-menu .el-sub-menu__title {
  /* 设置默认颜色 */
  color: #ffffff;
}

.custom-menu-title {
  width: 8vh;
  color: #ffffff;
}

.custom-menu-title.is-active {
  color: #ffffff;
  background-color: #3c3c68 !important;
}


.custom-menu .el-menu-item.is-active,
.custom-menu .el-sub-menu__title.is-active {
  /* 设置激活状态的颜色 */
  color: #ffffff;
  background-color: #373786;
}

/* 子菜单项的样式 */
.custom-menu .el-sub-menu .el-menu-item {
  /* 子菜单项默认颜色 */
  color: #ffffff;
  background-color: #3c3c68;
}

/* 子菜单项激活状态的样式 */
.custom-menu .el-sub-menu .el-menu-item.is-active {
  color: #ffffff;
  background-color: #373786;
}
</style>

<template>
    <el-row>
        <el-col :span="24" class="sub_title">
            <h6>爬取时间和爬取来源</h6>
        </el-col>
        <el-col :span="6">
          <el-form-item label="爬取时间:" size="default">
            <el-date-picker
              v-model="fetchTime"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 100%"
              size="small"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="6">
          <el-form-item label="爬取来源:"  size="default">
            <el-input size="small" v-model="fetchSource" placeholder="请输入爬取来源" />
          </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-col :span="24" class="sub_title">
            <h6>新闻标题</h6>
        </el-col>
        <el-col :span="12">
          <el-form-item label="" size="default">
            <el-input size="small" v-model="newsTitle" placeholder="请输入新闻标题" />
          </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-col :span="24" class="sub_title">
            <h6>新闻简介</h6>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" size="default">
            <el-input size="small" type="textarea" :rows="3" v-model="newsTitle" placeholder="请输入新闻简介" />
          </el-form-item>
        </el-col>
    </el-row>
    
    <el-row>
        <el-col :span="24" class="sub_title">
            <h6>新闻详情</h6>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" size="default">
            <el-input size="small" type="textarea" :rows="6" v-model="newsTitle" placeholder="请输入新闻详情" />
          </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-col :span="24" class="sub_title">
            <h6>信息智能提取</h6>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" size="default">
            <el-input size="small" type="textarea" :rows="6" v-model="newsTitle" placeholder="信息智能提取" />
          </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-col :span="24" class="sub_title">
            <h6>资讯关联企业和项目</h6>
        </el-col>
        <el-col :span="12">
            <el-tag v-for="item in associatedEntities" :key="item.id">{{ item.name }}</el-tag>
        </el-col>
    </el-row>

    <el-row>
        <el-col :span="6" :offset="6">
            <el-button @click="cancel">取消修改</el-button>
        </el-col>
        <el-col :span="6" >
            <el-button type="primary" @click="confirm">保存修改</el-button>
        </el-col>
    </el-row>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import { ElInput, ElDatePicker, ElRow, ElCol, ElFormItem, ElTag, ElButton } from 'element-plus';

// 假设数据初始化
const fetchTime = ref(new Date());
const fetchSource = ref('新闻网站A');
const newsTitle = ref('');
const newsSummary = ref('');
const newsContent = ref('');

// 模拟信息智能提取结果
const extractedInfo = computed(() => `提取的关键信息: ${newsContent.value}`);

// 关联企业和项目列表
const associatedEntities = ref([
  { id: 1, name: '公司X' },
  { id: 2, name: '项目Y' }
]);

// 模拟点击事件
const cancel = () => {
  console.log('取消');
};

const confirm = () => {
  console.log('确认修改');
};

onMounted(() => {
  // 初始化数据或加载已有新闻数据
});
</script>

<style scoped>
h6 {
    margin: 0px 0px 5px 0px;
    padding: 0px;
}

.sub_title {
    margin: 0;
    padding: 0;
    text-align: left;
}
</style>